import React, { useEffect } from 'react';

import { SEO } from '../components';
import { Background, Product } from '../lib/types';
import { useAppDispatch } from '../redux/hooks';
import { changeBackground, changeCurrentLogo } from '../redux/reducers/ui';
import { Home, PageLayout } from '../widgets';

import '../assets/fonts/jakarta.css';

const BridgePage = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(changeBackground(Background.Secondary));
    dispatch(changeCurrentLogo({ product: Product.Bridge }));
  }, [dispatch]);

  return (
    <>
      <SEO
        title="Arteria Bridge"
        description="The ONLY SPEI to Solana Bridge"
        image="../assets/images/logos/bridge.svg"
      />
      <PageLayout isOverflowActive>
        <Home />
      </PageLayout>
    </>
  );
};

export default BridgePage;
